import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import React from 'react';
import i18n from 'i18n';

export default class CalendarTable extends React.Component {
  calendarComponentRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.dateObject !== prevProps.dateObject) {
      const calendarApi = this.calendarComponentRef.current.getApi();
      calendarApi.gotoDate(this.props.dateObject.format('Y-MM-DD'));
    }
  }

  selectReservation = (info) => {
    if (info.event.rendering !== 'background') {
      this.props.setSelectedReservation(info.event);
      this.props.showReservationDetails(true);
    }
  };

  changeDate(quantity, unit) {
    this.props.changeCalendarDate(quantity, unit).then(() => {});
  }

  dateClick(event) {
    if (this.props.dateClick) {
      this.props.dateClick(event.date);
    }
  }

  renderTaskTitle({ el, event }) {
    if (event.extendedProps.task) {
      const titleDiv = document.createElement('div');
      titleDiv.innerHTML = event.extendedProps.task;
      titleDiv.classList.add('task-title-container');
      el.appendChild(titleDiv);
    }
  }

  renderModifiedReservations = () => {
    // remove overlapped tasks
    const data = this.props.reservations;

    return data.filter((item, index, arr) => {
      if (!item.task) return true; // Keep objects without a "task" field

      const taskStart = new Date(item.start);
      const taskEnd = new Date(item.end);

      return !arr.some((other) => {
        if (other.id === item.id || other.task) return false; // Ignore self & other tasks
        const otherStart = new Date(other.start);
        const otherEnd = new Date(other.end);

        return taskStart >= otherStart && taskEnd <= otherEnd;
      });
    });
  };

  render() {
    const { t } = this.props;
    const modifiedReservations = this.renderModifiedReservations();

    return (
      <div className="calendar-table-container">
        <div className="calendar-header">
          <div className="year-column">
            <span className="prevYear">
              <i className="icon icon-prev" onClick={this.changeDate.bind(this, -1, 'year')} />
            </span>
            <span className="year">{this.props.dateObject.format('Y')}</span>
            <span className="nextYear">
              <i className="icon icon-next" onClick={this.changeDate.bind(this, 1, 'year')} />
            </span>
          </div>
          <div className="month-column">
            <span className="prevMonth">
              <i className="icon icon-prev" onClick={this.changeDate.bind(this, -1, 'month')} />
            </span>
            <span className="month">
              {t(`common:${this.props.dateObject.format('MMMM').toLowerCase()}`)}
            </span>
            <span className="nextMonth">
              <i className="icon icon-next" onClick={this.changeDate.bind(this, 1, 'month')} />
            </span>
          </div>
        </div>
        <FullCalendar
          dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
          locale={i18n.language}
          defaultView="dayGridMonth"
          defaultDate={this.props.dateObject.format('YYYY-MM-DD')}
          dateClick={this.dateClick.bind(this)}
          displayEventTime={false}
          contentHeight="auto"
          plugins={[dayGridPlugin, interactionPlugin]}
          ref={this.calendarComponentRef}
          firstDay={1}
          header={{
            left: '',
            center: '',
            right: '',
          }}
          weekends
          events={modifiedReservations}
          eventClick={this.selectReservation}
          eventRender={this.renderTaskTitle}
        />
      </div>
    );
  }
}
